<template>
  <div v-if="shouldShowHeaderMenu" class="fixed-header-menu">
    <HeaderMenu/>
  </div>
  <router-view/>
  <div v-if="shouldShowHeaderMenu" class="fixed-footer-menu">
    <FooterMenu/>
  </div>
</template>

<script setup>
import HeaderMenu from './views/HeaderMenu.vue'
import FooterMenu from './views/FooterMenu.vue';
import {onMounted, ref, watch} from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const shouldShowHeaderMenu = ref(route.meta.requiresAuth);

watch(
  () => route.meta.requiresAuth,
  (newValue) => {
    shouldShowHeaderMenu.value = newValue;
  }
)

onMounted(()=>{
  // 设置 primary 颜色
  document.body.style.setProperty('--el-color-primary', 'rgba(0,158,142, 1)');

// 设置 primary-light 颜色
  document.body.style.setProperty('--el-color-primary-light-9', 'rgba(0,158,142, 0.1)');
  document.body.style.setProperty('--el-color-primary-light-8', 'rgba(0,158,142, 0.2)');
  document.body.style.setProperty('--el-color-primary-light-7', 'rgba(0,158,142, 0.3)');
  document.body.style.setProperty('--el-color-primary-light-6', 'rgba(0,158,142, 0.4)');
  document.body.style.setProperty('--el-color-primary-light-5', 'rgba(0,158,142, 0.5)');
  document.body.style.setProperty('--el-color-primary-light-4', 'rgba(0,158,142, 0.6)');
  document.body.style.setProperty('--el-color-primary-light-3', 'rgba(0,158,142, 0.7)');
  document.body.style.setProperty('--el-color-primary-light-2', 'rgba(0,158,142, 0.8)');
  document.body.style.setProperty('--el-color-primary-light-1', 'rgba(0,158,142, 0.9)');

})
</script>

<style lang="less">
.fixed-header-menu{
  position: sticky;
  z-index: 1000;
  top: 0;
  width: 100%;
}
body, html{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: scroll;
  &::-webkit-scrollbar{
    display: none;
  }
}

.fixed-footer-menu {
  bottom: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1); 
}
</style>
