import axios from 'axios';
// 创建axios实例
const http = axios.create({
    // baseURL: 'http://83.229.127.191:8089/', // api的base_url
    timeout: 30000 // 请求超时时间
});

// 设置Content-Type为application/json，这对于POST请求通常是必要的
http.defaults.headers.post['Content-Type'] = 'application/json';


// 新的 /bot 代理实例
const httpBot = axios.create({
    baseURL: '/bot',
    timeout: 30000,
  });
httpBot.defaults.headers.post['Content-Type'] = 'multipart/form-data';

export default http;

