<template>
  <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" background-color="#e8f5e9"
    text-color="#009e8e" active-text-color="orangered" ellipsis @select="handleSelect">
    <el-menu-item index="99" Style="">
      <img style="width: 140px" src="@/assets/logoS.png" alt="Tron logo" />
    </el-menu-item>
    <el-menu-item index="0" style="margin-right: 40px;">首页</el-menu-item>
    <el-sub-menu index="1">
      <template #title>TRON·收款地址靓号</template>
      <el-menu-item index="2-0">TUFDZ靓号馆</el-menu-item>
      <el-menu-item index="2-1">靓号交易订单查询</el-menu-item>
      <el-menu-item index="2-2">一键多签</el-menu-item>
      <!-- <el-menu-item index="2-3">其他功能</el-menu-item> -->
    </el-sub-menu>
    <!-- <el-menu-item index="4">靓号交易订单查询</el-menu-item> -->
    <el-menu-item index="5">TRX自助兑换</el-menu-item>
    <el-menu-item index="6">能量租赁</el-menu-item>
    <el-menu-item index="7">富豪榜</el-menu-item>
    <el-sub-menu index="9" >
      <template #title>付费服务</template>
      <el-menu-item index="9-1">封锁地址</el-menu-item>
      <el-menu-item index="9-2">地址追踪</el-menu-item>
      <el-menu-item index="9-3">汇聚归集</el-menu-item>
      <el-menu-item index="9-4">交易分析</el-menu-item>
    </el-sub-menu>
    <!-- <el-menu-item index="10">归集系统</el-menu-item> -->
    <el-menu-item index="2">TG-VPI-Group
      <img src="@/assets/shareBlack.png" class="menu-icon" />
    </el-menu-item>
    <el-menu-item index="3">联系客服
      <img src="@/assets/shareBlack.png" class="menu-icon" />
    </el-menu-item>
    <el-menu-item index="8" v-if="isLoggedIn" style="margin-left: 40px;" @click="userInfoVisible">个人中心</el-menu-item>
    <el-menu-item index="8" v-else style="margin-left: 40px;" @click="userInfoVisible">登录注册</el-menu-item>
  </el-menu>

  <!-- 登录弹窗 -->
  <el-dialog v-model="loginVisible"
    width="460px"
    style="background-color: rgba(108, 22, 22, 0);
    box-shadow: none;"
    align-center center
    :show-close=false
    :lock-scroll="false"
    >
    <login :visible="loginVisible" @updateVisible="handleUpdateVisible" />
  </el-dialog>
</template>


<script setup>
import { ref, computed, onMounted,watch} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import store from '@/store';
import login from '@/views/Login.vue';
import { ElMessage } from 'element-plus';
import { get_user_info } from '@/utils/Api'



const activeIndex = ref('0');
const router = useRouter();

const handleSelect = (key) => {
  if (key === '3') {
    window.open('https://t.me/TUFDZ')
  }
  if (key === '2') {
    window.open('https://t.me/TUFULH')
  }
  activeIndex.value = key;

  if (key === '1') {
    router.push({ name: 'tron' });
  }

  if (String(key).charAt(0)  === '9') {
    if (!isLoggedIn.value) {
      ElMessage({
      message: '请先登录',
      type: 'warning',
      duration: 2000,
    });
    return userInfoVisible();
    }
    if(userType.value !== "至尊VIP用户") {
      ElMessage({
      message: '权限不足',
      type: 'warning',
      duration: 2000,
    });
    return handleSelect("0");
    }
  }

  // 根据key值进行页面切换
  let routeName = getRouteName(key);
  // location.href = "https://cxu.io/"+routeName
  router.push({ name: routeName });
};

const getRouteName = (key) => {
  switch (key) {
    case '0':
      return 'mainPage';
    case '1':
      return 'tron';
    case '2':
      break
      case '2-1':
        return 'order';
      case '2-2':
        return 'multisig';
    case '3':
      break
    case '5':
      return 'exchange';
    case '6':
      return 'lease';
    case '7':
      return 'richList';
    case '8':
      return '';
    case '9-1':
      return 'banned';
    case '9-2':
      return '';
    case '9-3':
      return 'collection';
    case '9-4':
      return 'transSum';
    case '10':
      return 'collection';
    default:
      return 'tron';
  }
};

const isLoggedIn = computed(() => store.getters.isLoggedIn); // 是否登录
const user = computed(() => store.getters.user); // 用户信息

const loginStatus = () => {
  console.log(isLoggedIn.value,user.value);
}

//登录
const loginVisible = ref(false);
async function handleUpdateVisible(newValue) {
  // console.log("组件传参",newValue);
  loginVisible.value = newValue;
}

const userInfoVisible = () => {
  // console.log(isLoggedIn.value,user.value);
  if (isLoggedIn.value) {
    // 已登录，跳转到个人中心
    router.push({ name: 'personal' });
  } else {
    // 未登录，显示登录弹窗
    loginVisible.value = true;
  }
};

const userType = ref("");
const getUserInfo = async () => {
  try {
    const res = await get_user_info(user.value.id);
    userType.value = res.data.data.userType;
    // console.log("userType",userType.value);
  } catch (error) {
    console.log(error);
  }
};
// 监听user变化，更新userType
watch(() => user.value, () => {
  if (user.value) {
    getUserInfo();
  }
});

onMounted(() => {
  if (user.value) {
    getUserInfo();
  }
})

</script>
<style lang="less" scoped>

.el-menu-demo {
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}
.switch-text {
  text-align: center;
  // margin-top: 20px;
  color: #409EFF;
  cursor: pointer;
}

.menu-icon {
  width: 16px; /* 设置图标的宽度 */
  height: 16px; /* 设置图标的高度 */
  margin-left: 6px; /* 设置图标的左边距 */
  vertical-align: middle; /* 垂直居中对齐 */
}

.el-menu--horizontal > .el-menu-item:nth-child(1) {
  margin-right: 6%;
}

@media (max-width: 768px) {
  .el-menu-demo {
    min-width: 100%;
    overflow: hidden;
    /* 藏超出的内容 */
    max-width: 420px;
    /* 让每个菜单项占据整行 */
  }

  .el-menu--horizontal>.el-menu-item:nth-child(1) {
    margin-right: 0;
  }
}
</style>
