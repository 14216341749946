import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'mainPage',
    component: () => import('../views/MainPage.vue'),
    meta: { requiresAuth: true }
  }, {
    path: '/transDetail',
    name: 'transDetail',
    component: () => import('../views/TransDetail.vue'),
    meta: { requiresAuth: true }
  }, {
    path: '/batch',
    name: 'batch',
    component: () => import('../views/TransDetailBatch.vue'),
    meta: { requiresAuth: true }
  }, {
    path: '/hashDetail',
    name: 'hashDetail',
    component: () => import('../views/HashDetail.vue'),
    meta: { requiresAuth: true }
  }, {
    path: '/banned',
    name: 'banned',
    component: () => import('../views/Banned.vue'),
    meta: { requiresAuth: true }
  }, {
    path: '/tron',
    name: 'tron',
    component: () => import('../views/Tron.vue'),
    meta: { requiresAuth: true }
  }, {
    path: '/order',
    name: 'order',
    component: () => import('../views/Order.vue'),
    meta: { requiresAuth: true }
  }, {
    path: '/jc',
    name: 'jc',
    component: () => import('../views/MobileJc.vue'),
    meta: { requiresAuth: true }
  }, {
    path: '/priceSetting',
    name: 'priceSetting',
    component: () => import('../views/PriceSetting.vue'),
    meta: { requiresAuth: true }
  }, {
    path: '/orderRecord',
    name: 'orderRecord',
    component: () => import('../views/OrderRecord.vue'),
    meta: { requiresAuth: true }
  }, {
    path: '/exchange',
    name: 'exchange',
    component: () => import('../views/Exchange.vue'),
    meta: { requiresAuth: true }
  }, {
    path: '/lease',
    name: 'lease',
    component: () => import('../views/Lease.vue'),
    meta: { requiresAuth: true }
  }, {
    path: '/richList',
    name: 'richList',
    component: () => import('../views/RichList.vue'),
    meta: { requiresAuth: true }
  }, {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta: { requiresAuth: true }
  }, {
    path: '/personal',
    name: 'personal',
    component: () => import('../views/Personal.vue'),
    meta: { requiresAuth: true }
  }, {
    path: '/multisig',
    name:'multisig',
    component: () => import('../views/Multisig.vue'),
    meta: { requiresAuth: true }
  }, {
    path: '/transSum',
    name:'transSum',
    component: () => import('../views/TransSum.vue'),
    meta: { requiresAuth: true }
  },{
    path: '/collection',
    name:'collection',
    component: () => import('../views/Collection.vue'),
    meta: { requiresAuth: true }
  },{
    path: '/collectionSettings',
    name:'collectionSettings',
    component: () => import('../views/CollectionSettings.vue'),
    meta: { requiresAuth: true }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
