import { createStore } from 'vuex'
import { onMounted } from 'vue'

// 初始化时恢复状态
const user = JSON.parse(localStorage.getItem('user')) || null;
const isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn')) || false;

export default createStore({
  state: {
    isLoggedIn: isLoggedIn, // 登录状态
    user: user,        // 用户信息
  },
  getters: {
    isLoggedIn: (state) => state.isLoggedIn,
    user: (state) => state.user,
  },
  mutations: {
    setLoggedIn(state, status) {
      state.isLoggedIn = status;
      localStorage.setItem('isLoggedIn', JSON.stringify(status));
    },
    setUser(state, user) {
      state.user = user;
      localStorage.setItem('user', JSON.stringify(user));
    },
  },
  actions: {
    login({ commit }, user) {
      commit('setLoggedIn', true);
      commit('setUser', user);
    },
    logout({ commit }) {
      commit('setLoggedIn', false);
      commit('setUser', null);
    },
  },
  modules: {
  }
})
