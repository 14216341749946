<script setup>

</script>

<template>
      <!-- 底部菜单 -->
      <div class="footer-menu">
      <div class="footer-menu-left">
        <span class="menu-item-left">© 2025 TronBeautiful</span>
      </div>
      <div class="menu-item">
        <div class="menu-item-title">探索者</div>
        <router-link to="/">BTC 探索者</router-link>
        <router-link to="/">ETH 探索者</router-link>
        <router-link to="/">USDT 浏览器</router-link>
        <router-link to="/">TRX 探索者</router-link>
        <router-link to="/">BSC 探索者</router-link>
        <router-link to="/">Dash 浏览器</router-link>
        <router-link to="/">更多的</router-link>
      </div>
      <div class="menu-item">
        <div class="menu-item-title">开发人员</div>
        <router-link to="/">区块链 API</router-link>
        <router-link to="/">地址追踪</router-link>
        <router-link to="/">交易追踪</router-link>
        <router-link to="/">节点即服务</router-link>
        <router-link to="/">NFT API</router-link>
        <router-link to="/">NFT认购</router-link>
        <router-link to="/">稳定币 API</router-link>
        <router-link to="/">市场数据</router-link>
      </div>
      <div class="menu-item">
        <div class="menu-item-title">信息</div>
        <router-link to="/">广告</router-link>
        <router-link to="/">教程</router-link>
        <router-link to="/">关于我们</router-link>
        <router-link to="/">服务条款</router-link>
        <router-link to="/">隐私政策</router-link>
        <router-link to="/">反馈</router-link>
      </div>
      <div class="menu-item">
        <div class="menu-item-title">语言</div>
        <router-link to="/">中文</router-link>
        <router-link to="/">English</router-link>
        <router-link to="/">日本語</router-link>
      </div>
    </div>
</template>

<style lang="less" scoped>
/* 底部菜单样式 */
.footer-menu {
  display: flex;
  justify-content: space-around;
  width: 100%;
  background-color: #e8f5e9;
  padding: 20px 0;
  margin-top: auto; /* 确保菜单在底部 */
}

.menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu-item a {
  color: rgba(0, 0, 0, 0.714);
  text-decoration: none;
  margin: 5px 0;
  font-size: 14px;
}

.menu-item a:hover {
  color: rgb(0 158 142); /* Element Plus 主题色 */
}
.menu-item-title {
  color: rgba(61, 59, 59, 0.603);
  font-size: 16px;
  margin-bottom: 16px;
}
.menu-item-left {
  color: rgba(0, 0, 0, 0.714);
  font-size: 14px;
}
.footer-menu-left {
  display: flex;
  align-items: center;
  margin-top: 40px;
  flex-direction: column;
}

@media (max-width: 768px) {
 .footer-menu {
    flex-direction: space-between;
    padding: 10px 0;
  }
  .menu-item-title {
    font-size: 14px;
  }
 .menu-item {
    margin: 10px 0;
  }
  .footer-menu-left {
    margin-top: 40px;
  }
}
</style>