<script setup>
import { ref ,onBeforeUnmount, onMounted ,computed,watch,defineProps,defineEmits } from 'vue';
import store from '@/store';
import { ElMessage } from 'element-plus';
import {get_user_info ,user_login,save_user,delete_user} from '@/utils/Api'
import { UserFilled,MoreFilled,Lock,User} from '@element-plus/icons-vue'
import { useRouter,useRoute } from 'vue-router';

const router = useRouter();
const route = useRoute();
const isLoggedIn = computed(() => store.getters.isLoggedIn); // 是否登录
const user = computed(() => store.getters.user); // 用户信息

//是否注册
const isRegister = ref(false);

// 表单数据
const loginForm = ref({
  username: '',
  password: '',
  code: '',
  keepSession: false,
  telegramCode: true,
});

const registerForm = ref({
  username: '',
  password: '',
  tgUid: null,
  confirmPassword: '',
});

// 登录逻辑
const handleLogin = async () => {

  const res = await user_login({
    username:loginForm.value.username,
    password:loginForm.value.password,
    code:loginForm.value.code,
  });
  // console.log('登录结果:', res.data);
  if(res.data.data > 0) {
    store.dispatch('login', {
      id: res.data.data,
      username: loginForm.value.username,
      password: loginForm.value.password,
    });
    loginForm.value= {
      username: '',
      password: '',
      code: '',
      keepSession: false,
      telegramCode: true,
    };
    ElMessage.success(
      {
        message: '登录成功',
        type:'success',
        duration: 1500,
      }
    );
    // console.log('登录成功',localStorage.getItem('user'));
    toggleVisibility();
    router.push({ name: 'personal' });
  }
  else if (res.data.data === -1) {
    ElMessage.error('验证码或用户名错误');
  }
  else if (res.data.data === -2) {
    ElMessage.error('密码错误');
  }
  else {
    ElMessage.error('未知错误');
  }
};

//注册
const handleRegister = async () => {
  if(registerForm.value.password !== registerForm.value.confirmPassword) {
    ElMessage.error('两次密码输入不一致');
    return;
  }

  const res = await save_user({
    username:registerForm.value.username,
    password:registerForm.value.password,
    tgUid:registerForm.value.tgUid,
  });
  // console.log('注册结果:',res.data);
  if(res.data.data === '用户信息保存成功') {
    registerForm.value= {
      username: '',
      password: '',
      tgUid: null,
      confirmPassword: '',
    };
    ElMessage.success('注册成功');
    isRegister.value = false;
  } 
  else if (res.data.data === 'isExist'){
    ElMessage.error('该用户UID已存在');
  }  
  else {
    ElMessage.error('注册失败');
  }
};

const loginFormRef = ref(null);
const registerFormRef = ref(null);

// 表单验证规则
const rules = {
  username: [
    { required: true, message: '用户名不能为空', trigger: 'blur' },
    { min: 6, message: '用户名长度不能少于6位', trigger: 'blur' },
  ],
  password: [
    { required: true, message: '密码不能为空', trigger: 'blur' },
    { min: 6, message: '密码长度不能少于6位', trigger: 'blur' },
  ],
  code: [
    { required: true, message: '验证码不能为空', trigger: 'blur' },
    { len: 4, message: '验证码必须为4位', trigger: 'blur' },
  ],
};
const registerRules = {
  username: [
    { required: true, message: '用户名不能为空', trigger: 'blur' },
    { min: 6, message: '用户名长度不能少于6位', trigger: 'blur' },
  ],
  tgUid: [
    { required: true, message: 'UID不能为空', trigger: 'blur' },
    { 
    pattern: /^\d{5,12}$/,
    message: 'UID应为5-12位纯数字',
    trigger: 'blur'
    }
  ],
  password: [
    { required: true, message: '密码不能为空', trigger: 'blur' },
    { min: 6, message: '密码长度不能少于6位', trigger: 'blur' },
  ],
  confirmPassword: [
    { required: true, message: '确认密码不能为空', trigger: 'blur' },
    { min: 6, message: '确认密码长度不能少于6位', trigger: 'blur' },
  ],
};


// 提交登录表单
const submitForm = () => {
  // 调用表单的 validate 方法进行验证
  loginFormRef.value.validate((valid) => {
    if (valid) {
      // 验证成功，执行后续方法
      // console.log('表单验证成功，可以执行后续操作');
      // 调用登录方法
      handleLogin();
    } else {
      // 验证失败，提示用户
      ElMessage.error('表单验证失败，请检查');
    }
  });
};

// 提交登录表单
const submitRegisterForm = () => {
  // 调用表单的 validate 方法进行验证
  registerFormRef.value.validate((valid) => {
    if (valid) {
      handleRegister();
    } else {
      // 验证失败，提示用户
      ElMessage.error('表单验证失败，请检查');
    }
  });
};

//登出
const logout = () => {
  store.dispatch('logout');
}

const getUser = async () => {
  // const res = await get_user_info(1);
  // console.log('用户:', res.data);
  // const resDelete = await delete_user(4);
  // console.log('test-结果:');

}
const changeButton = () => {
  isRegister.value = !isRegister.value;
  loginForm.value= {
    username: '',
    password: '',
    code: '',
    keepSession: false,
    telegramCode: true,
  }
  registerForm.value= {
    username: '',
  password: '',
  tgUid: null,
  confirmPassword: '',
  }
}

const props = defineProps(
  {
    visible:Boolean,
  }
);
const emit = defineEmits(['updateVisible']);
function toggleVisibility() {
  emit('updateVisible', !props.visible);
}

watch(() => props.visible, (newValue) => {
  if(props.visible) {
    loginForm.value= {
    username: '',
    password: '',
    code: '',
    confirmPassword: '',
    keepSession: false,
    telegramCode: true,
    }
    registerForm.value= {
      username: '',
      password: '',
      tgUid: null,
      confirmPassword: '',
      }
  //表单验证规则清空
    if(loginFormRef.value) {
      loginFormRef.value.resetFields();
    }
    if(registerFormRef.value) {
      registerFormRef.value.resetFields();
    }
  }
});

onMounted(() => {

});

</script>

<template>
  <div class="login-container">
    <!-- 登录表单 -->
    <div class="login-form">
      <!-- 顶部图片 -->
      <div class="login-header">
        <img src="@/assets/login-head.png" alt="Login Header" class="header-image">
        <img src="@/assets/avatar.png" alt="Telegram" class="user-image" />
      </div>
      <div class="login-body-form">
        <!-- 登录表单 -->
        <el-form v-if="!isRegister" :model="loginForm" :rules="rules" ref="loginFormRef">
          <!-- 用户名 -->
          <el-form-item prop="username">
            <el-input v-model="loginForm.username" placeholder="用户名" :prefix-icon="UserFilled" />
          </el-form-item>

          <!-- 密码 -->
          <el-form-item prop="password">
            <el-input v-model="loginForm.password" type="password"  placeholder="密码" :prefix-icon="Lock" show-password/>
          </el-form-item>

          <!-- 验证码 -->
          <el-form-item  prop="code">
            <div style="display: flex; ">
            <el-input v-model="loginForm.code" placeholder="验证码" :prefix-icon="MoreFilled" class="captcha-input" />
            <!-- <img src="" alt="验证码" class="captcha-image" /> -->
             <!-- <el-button type="primary" style="width: 30%;" @click="getUser" >验证码</el-button>-->
            </div>
          </el-form-item>

          <!-- 复选框 -->
          <el-form-item v-if="!isRegister">
            <el-checkbox v-model="loginForm.keepSession">保持会话</el-checkbox>
            <el-checkbox v-model="loginForm.telegramCode">验证码</el-checkbox>
          </el-form-item>
        </el-form>

        <!-- 注册表单 -->
        <el-form v-if="isRegister" :model="registerForm" :rules="registerRules" ref="registerFormRef">
          <!-- 用户名 -->
          <el-form-item prop="username">
            <el-input v-model="registerForm.username" placeholder="用户名" :prefix-icon="UserFilled" />
          </el-form-item>

          <!-- uid -->
          <el-form-item prop="tgUid">
            <el-input v-model="registerForm.tgUid"  placeholder="UID" :prefix-icon="User" show-password/>
          </el-form-item>          

          <!-- 密码 -->
          <el-form-item prop="password">
            <el-input v-model="registerForm.password" type="password"  placeholder="密码" :prefix-icon="Lock" show-password/>
          </el-form-item>

          <!-- 确认密码 -->
          <el-form-item prop="confirmPassword">
            <el-input v-model="registerForm.confirmPassword" type="password" placeholder="确认密码" :prefix-icon="Lock" show-password/>
          </el-form-item>
        </el-form>
        <!-- 登录按钮 -->
        <div  v-if ="!isRegister" style="display: flex; justify-content: center; margin-top: 10px;">
          <el-button type="primary" @click="submitForm">登录</el-button>
        </div>
        <!-- 登出 -->
        <!-- <div v-if="isLoggedIn" style="display: flex; justify-content: center; margin-top: 10px;">
          <el-button type="warning" @click="logout">退出</el-button>
        </div> -->
        <!-- 注册 -->
        <div  v-if ="isRegister" style="display: flex; justify-content: center; margin-top: 10px;">
          <el-button type="primary" @click="submitRegisterForm">注册</el-button>
        </div>
        <div class="switch-text">
        <span v-if="!isRegister" @click="changeButton">没有账号，立即注册</span>
        <span v-if="isRegister" @click="changeButton">已有账号，立即登录</span>
      </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
  // height: 100vh;
  margin: 0;
  // background-color: rgba(0, 0, 0, 0.5); /* 与遮罩颜色一致 */
}

.login-header {
  margin-bottom: 10px;
  background-color: #708fead7;
  position: relative;
  margin-bottom: 40px;
}

.header-image {
  width: 100%;
  max-width: 400px;
}

.login-form {
  width: 100%;
  max-width: 400px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.captcha-input {
  width: 80%;
  margin-right: 10px;
}

.captcha-image {
  width: 30%;
  height: auto;
  cursor: pointer;
}

.el-form-item {
  margin-bottom: 20px;
}

.el-button {
  width: 100%;
}
.login-body-form {
  padding: 30px;
}

.user-image {
  position: absolute;
  top: 96px;
  right: 150px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.switch-text {
  margin-top: 20px;
  text-align: center;
  // margin-top: 20px;
  color: #409EFF;
  cursor: pointer;
}

@media (max-width: 768px) {
 .login-header {
    margin-bottom: 20px;
  }
 .user-image {
    top: 80px;
    right: 125px;
    width: 70px;
    height: 70px;
  }
  .login-container {
    padding: 0 20px;
  }
}

</style>
