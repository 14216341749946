import axios from "@/utils/http";

const prefix = "/api/"


// 走国外服务器
const prefixGlobal = "/apG/"



async function request(params) {
    try {
        return await axios(params);
    } catch (error) {
        console.log(`请求失败: ${params.url}`, error);
    }
}

export async function get_data(data) {
    return await request({
        url: prefix + 'tron/query',
        method: 'post',
        data
    })
}

export async function get_bot() {
    return await request({
        url: prefix + 'address/getBot',
        method: 'get',
    })
}

export async function get_address() {
    return await request({
        url: prefix + 'address/getAddress',
        method: 'get',
    })
}

export async function get_receiveAddress(data) {
    return await request({
        url:  prefix + 'receiveAddress/getOneReceiveAddress?type=' + data,
        method: 'get',
    })
}

export async function fetch_address(data) {
    return await request({
        url: prefix + 'address/page?page=+' + data.page + '&limit=' + data.size,
        method: 'get',
    })
}

export async function del_all(data) {
    return await request({
        url: prefix + 'address/delAll',
        method: 'post',
        data
    })
}

export async function query_tron(data) {
    return await request({
        url: prefix + 'tron/query',
        method: 'post',
        data
    })
}

export async function get_tron(address) {
    return await request({
        url: prefix + `tron/${address}`,
        method: 'get'
    })
}

export async function add_tron(data) {
    return await request({
        url: prefix + 'tron/add',
        method: 'post',
        data
    })
}

export async function delete_tron(data) {
    return await request({
        url: prefix + 'tron/delete',
        method: 'post',
        data
    })
}

export async function query_order(id) {
    return await request({
        url: prefix + `order/getOrder/${id}`,
        method: 'get'
    })
}

export async function query_exchangeOrder(id) {
    return await request({
        url: prefix + `exchange/getOrder/${id}`,
        method: 'get'
    })
}

export async function query_price() {
    return await request({
        url: prefix + `price/get_price`,
        method: 'get'
    })
}

export async function update_price(data) {
    return await request({
        url: prefix + `price/update_price`,
        method: 'post',
        data
    })
}

export async function update_prices(data) {
    return await request({
        url: prefix + `price/update_prices`,
        method: 'post',
        data
    })
}

export async function get_price(id) {
    return await request({
        url: prefix + `price/${id}`,
        method: 'get'
    })
}

export async function create_order(data) {
    return await request({
        url: prefix + `order/create`,
        method: 'post',
        data
    })
}

export async function create_exchangeOrder(data) {
    return await request({
        url: prefix + `exchange/createOrder`,
        method: 'post',
        data
    })
}

export async function check_address(data) {
    return await request({
        url: prefix + `order/checkAddress`,
        method: 'post',
        data
    })
}

export async function query_advert(data) {
    return await request({
        url: prefix + 'advert/query?type=' + data,
        method: 'get',
    })
}

export async function query_menu(data) {
    return await request({
        url: prefix + 'advert/menuQuery?type=' + data,
        method: 'get',
    })
}

export async function query_trx_detail(data) {
    return await request({
        url: prefix + 'address/getTrxTransactions',
        method: 'post',
        data
    })
}
export async function query_trx_detail_usdt(data) {
    return await request({
        url: prefix + 'address/getTrxTransactionsUSDT',
        method: 'post',
        data
    })
}
export async function getTransactionInfo(hash) {
    return await request({
        url: prefix + 'address/getTransactionInfo?hash='+hash,
        method: 'get',
    })
}

export async function get_balances(data) {
    return await request({
        url: prefixGlobal + 'address/getBalances?trx=' + data,
        method: 'get',
    })
}
export async function get_account_info(data) {
    return await request({
        url: prefixGlobal + 'address/getAccountInfo?trx=' + data,
        method: 'get',
    })
}

export async function realUsdtPrice() {
    return await request({
        url: prefixGlobal + 'address/realUsdtPrice',
        method: 'get',
    })
}


export async function get_e(data) {
    return await request({
        url: prefixGlobal + 'address/getE?trx=' + data,
        method: 'get',
    })
}

//用户接口
export async function get_user_info(id) {
    return await request({
        url: prefix + `user/get/${id}`,
        method: 'get',
    })
}

export async function delete_user(id) {
    return await request({
        url: prefix + `user/delete/${id}`,
        method: 'get',
    })
}

export async function save_user(data) {
    return await request({
        url: prefix + 'user/save',
        method: 'post',
        data
    })
}

export async function user_login(data) {
    return await request({
        url: prefix + 'user/login',
        method: 'post',
        data
    })
}

export async function get_richList(data) {
    return await request({
        url: prefix + `address/getRichList?type=`+data,
        method: 'get',
    })
}

export async function update_receiveAddress(data) {
    return await request({
        url:  prefix +'receiveAddress/update',
        method: 'post',
        data
    })
}
export async function get_today_trade() {
    return await request({
        url: prefix + 'payRecord/getTodayTrade',
        method: 'get',
    })
}

export async function multiSign_submit(data) {
    return await request({
        url: prefix + 'tron/multiSign',
        method: 'post',
        data
    })
}
// 设置接口
export async function get_energy_set(id) {
    return await request({
        url: prefix + `energy/list/${id}`,
        method: 'get',
    })
}
export async function get_master_set(id) {
    return await request({
        url: prefix + `master/list/${id}`,
        method: 'get',
    })
}
export async function get_transfer_set(id) {
    return await request({
        url: prefix + `transfer/list/${id}`,
        method: 'get',
    })
}

export async function save_energy_set(data) {
    return await request({
        url: prefix + 'energy/save',
        method: 'post',
        data
    })
}
export async function save_master_set(data) {
    return await request({
        url: prefix + 'master/save',
        method: 'post',
        data
    })
}
export async function save_transfer_set(data) {
    return await request({
        url: prefix + 'transfer/save',
        method: 'post',
        data
    })
}
export async function delete_energy_set(id) {
    return await request({
        url: prefix + `energy/delete/${id}`,
        method: 'get',
    })
}
export async function delete_master_set(id) {
    return await request({
        url: prefix + `master/delete/${id}`,
        method: 'get',
    })
}
export async function delete_transfer_set(id) {
    return await request({
        url: prefix + `transfer/delete/${id}`,
        method: 'get',
    })
}

// 能量兑换--查询单笔/多笔的值和平台剩余可租能量
export async function get_energy(type) {
    return await request({
        url: prefix + `lease/getEnergy/${type}`,
        method: 'get',
    })
}

// 能量兑换-- 下单接口
export async function create_lease_order(data) {
    return await request({
        url: prefix + "lease/createLeaseOrder",
        method: 'post',
        data
    })
}
